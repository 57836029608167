import Head from "next/head";
import React, { useCallback, useEffect, useState } from "react";
import { InitiatePaymentParams } from "../../api/interfaces/payments";
import { InitiatePublicPayment } from "../../api/payments";
import { useRequest } from "../../api/utils";
import { InvoiceInterface, INVOICE_STATUSES } from "../../assets/interfaces/invoices";
import { PAYMENT_METHODS } from "../../assets/interfaces/payments";
import useSteps from "../hooks/useSteps";
import Portal from "../portal";
import PaystackPayment from "./paystack-payment";
import ZillaPayment from "./zilla-payment";
import BankTransfers from "./bank-transfers";
import { io } from "socket.io-client";
import SelectPaymentMethod from "./select-payment-method";
import PaymentSuccessful from "./payment-successful";
import PaymentFailed from "./payment-failed";
import PaymentAwaitingConfirmation from "./awaiting-confirmation";
import PaymentMade from "./payment-made";
import NoPaymentInformation from "./no-payment";
import { toast } from "../ui/toast";
import { emit } from "../hooks/useListener";
import MonoDirectDebit from "./directpay-payment";
import { OrderInterface } from "../../assets/interfaces";
import ThepeerPayment from "./thepeer-payment";

interface IProps {
  invoiceData: {
    preview_amount: number;
    invoice: string;
    initial_status: INVOICE_STATUSES;
    storeName: string;
    storePhone: string;
    receipt?: string;
    currency: string;
  };
  orderId?: string;
  onPaymentSuccess?: VoidFunction;
  payFor?: InvoiceInterface["receiver"];
  byCustomer?: boolean;
  chowbotPhone?: string;
  order?: OrderInterface;
}

enum PAYMENT_STEPS {
  SELECT_METHOD = "SELECT_METHOD",
  MAKE_BANK_TRANSFER = "MAKE_BANK_TRANSFER",
  WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION",
  PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  NO_PAYMENT_INFORMATION = "NO_PAYMENT_INFORMATION",
  PAYMENT_MADE = "PAYMENT_MADE",
}

const MakePayment: React.FC<IProps> = (props) => {
  const { invoiceData, orderId, onPaymentSuccess, payFor = null, byCustomer = false, order, chowbotPhone } = props;
  const type = orderId ? "ORDER" : "INVOICE";
  const initiatePaymentRequest = useRequest<InitiatePaymentParams>(InitiatePublicPayment);
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHODS>(null);
  const { step, changeStep } = useSteps(
    [
      PAYMENT_STEPS.SELECT_METHOD,
      PAYMENT_STEPS.MAKE_BANK_TRANSFER,
      PAYMENT_STEPS.WAITING_FOR_CONFIRMATION,
      PAYMENT_STEPS.PAYMENT_SUCCESSFUL,
      PAYMENT_STEPS.PAYMENT_FAILED,
      PAYMENT_STEPS.NO_PAYMENT_INFORMATION,
      PAYMENT_STEPS.PAYMENT_MADE,
    ],
    0
  );
  const paymentData = initiatePaymentRequest?.response?.data;
  const [statusUnknowm, setStatusUnknowm] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [receiptId, setReceiptId] = useState(invoiceData?.receipt);

  ///for payment widget on storefront
  useEffect(() => {
    if (step === PAYMENT_STEPS.SELECT_METHOD) {
      emit("payment-not-started", true);
    } else {
      emit("payment-not-started", false);
    }
  }, [step]);

  useEffect(() => {
    if (!invoiceData.invoice) {
      changeStep(PAYMENT_STEPS.NO_PAYMENT_INFORMATION);
      return;
    }

    if (invoiceData.initial_status === INVOICE_STATUSES.PAID) {
      changeStep(PAYMENT_STEPS.PAYMENT_MADE);

      return;
    }

    // initiate socket connection
    const socket = io(process.env.NEXT_PUBLIC_API_URL);

    socket.on("connect", () => {
      // console.log(socket);
    });

    socket.on(`PAYMENTS.${invoiceData.invoice}`, (data) => {
      setPaymentSuccessful(true);
      setReceiptId(data?.payload?.receipt);
      onPaymentSuccess && onPaymentSuccess();
      changeStep(PAYMENT_STEPS.PAYMENT_SUCCESSFUL);
    });
  }, []);

  useEffect(() => {
    const handleWindowClose = (e) => {
      e = e || window.event;
      // window.confirm

      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = "Please keep this page open to complete your payment";
      }

      // For Safari
      return "Please keep this page open to complete your payment";
    };

    if (paymentData && !paymentSuccessful && !statusUnknowm) {
      window.addEventListener("beforeunload", handleWindowClose);
    } else {
      window.removeEventListener("beforeunload", handleWindowClose);
    }

    return () => window.removeEventListener("beforeunload", handleWindowClose);

    // if (show && cart.length > 0) {
    //   //Notice before closing window
    //   window.addEventListener("beforeunload", handleWindowClose);
    // } else {
    //   window.removeEventListener("beforeunload", handleWindowClose);
    // }
  }, [paymentSuccessful, statusUnknowm, paymentData]);

  const initiatePayment = async (payment_method_type: PAYMENT_METHODS) => {
    setPaymentMethod(payment_method_type);

    const [res, err] = await initiatePaymentRequest.makeRequest({
      type: "INVOICE",
      payment_method_type,
      invoice: invoiceData?.invoice,
      currency: invoiceData?.currency,
    });

    if (res) {
      switch (payment_method_type) {
        case PAYMENT_METHODS.TRANSFER:
          changeStep(PAYMENT_STEPS.MAKE_BANK_TRANSFER);
          break;
        case PAYMENT_METHODS.PAYSTACK:
          break;
        case PAYMENT_METHODS.ZILLA:
          //initiate zilla payment
          break;
        case PAYMENT_METHODS.MONO_DIRECT_PAY:
          // initiate mono payment
          break;
        default:
        //do nothing
      }

      return;
    }

    //error for if initiation failed
    toast.error({ title: "Initiation Failed", message: err?.message ?? "Please try another payment method" });
  };

  function onSuccess(reference?: any) {
    if (step === PAYMENT_STEPS.SELECT_METHOD) {
      changeStep(PAYMENT_STEPS.WAITING_FOR_CONFIRMATION);
    }
  }

  function onError() {
    changeStep(PAYMENT_STEPS.PAYMENT_FAILED);
  }

  return (
    <>
      {step === PAYMENT_STEPS.SELECT_METHOD && (
        <SelectPaymentMethod
          paymentMethod={paymentMethod}
          invoiceData={invoiceData}
          initiatePayment={initiatePayment}
          initiatePaymentRequest={initiatePaymentRequest}
          payFor={payFor}
          byCustomer={byCustomer}
          // payTo={payTo}
        />
      )}

      {step === PAYMENT_STEPS.PAYMENT_SUCCESSFUL && (
        <PaymentSuccessful
          amount={invoiceData.preview_amount}
          reference={paymentData?.reference}
          storeName={invoiceData.storeName}
          storePhone={invoiceData.storePhone}
          orderId={orderId ?? invoiceData.invoice}
          type={type}
          payFor={payFor}
          chowbotPhone={chowbotPhone}
          receipt={receiptId}
          order={order}
          currency={invoiceData?.currency}
        />
      )}

      {step === PAYMENT_STEPS.PAYMENT_MADE && (
        <PaymentMade
          storePhone={invoiceData.storePhone}
          orderId={orderId ?? invoiceData.invoice}
          type={type}
          payFor={payFor}
          // payTo={payTo}
          receipt={receiptId}
        />
      )}

      {step === PAYMENT_STEPS.NO_PAYMENT_INFORMATION && (
        <NoPaymentInformation
          storePhone={invoiceData.storePhone}
          storeName={invoiceData.storeName}
          orderId={orderId ?? invoiceData.invoice}
        />
      )}

      {step === PAYMENT_STEPS.PAYMENT_FAILED && (
        <PaymentFailed
          amount={invoiceData.preview_amount}
          reference={paymentData?.reference}
          restart={() => changeStep(PAYMENT_STEPS.SELECT_METHOD)}
          unsure={statusUnknowm}
          currency={invoiceData?.currency}
        />
      )}

      {step === PAYMENT_STEPS.WAITING_FOR_CONFIRMATION && (
        <PaymentAwaitingConfirmation
          paymentSuccessful={paymentSuccessful}
          handleSuccess={() => changeStep(PAYMENT_STEPS.PAYMENT_SUCCESSFUL)}
          triggerError={() => {
            setStatusUnknowm(true);
            changeStep(PAYMENT_STEPS.PAYMENT_FAILED);
          }}
        />
      )}

      {step === PAYMENT_STEPS.MAKE_BANK_TRANSFER && paymentData && (
        <BankTransfers
          goBack={() => changeStep(PAYMENT_STEPS.SELECT_METHOD)}
          paymentData={{
            amount: paymentData?.total_amount,
            bank: paymentData?.bank,
          }}
          triggerError={() => {
            setStatusUnknowm(true);
            changeStep(PAYMENT_STEPS.PAYMENT_FAILED);
          }}
        />
      )}

      <Portal>
        {paymentMethod === PAYMENT_METHODS.PAYSTACK && paymentData !== undefined && (
          <PaystackPayment
            paymentData={{
              email: paymentData?.customer?.email || "holla@catlog.shop",
              amount: paymentData?.total_amount,
              reference: paymentData?.reference,
              publicKey: paymentData?.public_key,
              currency: paymentData?.currency,
            }}
            onClose={onError}
            callback={onSuccess}
          />
        )}

        {paymentMethod === PAYMENT_METHODS.THEPEER && paymentData !== undefined && (
          <ThepeerPayment
            paymentData={{
              email: paymentData?.customer?.email || "holla@catlog.shop",
              customer: paymentData?.customer?.id,
              amount: paymentData?.total_amount,
              reference: paymentData?.reference,
              publicKey: paymentData?.public_key,
            }}
            onClose={onError}
            onSuccess={onSuccess}
          />
        )}

        {paymentMethod === PAYMENT_METHODS.ZILLA && paymentData !== undefined && (
          <ZillaPayment
            paymentData={{
              orderCode: paymentData?.zilla_order_id,
              amount: paymentData?.total_amount,
              reference: paymentData?.reference,
              publicKey: paymentData?.public_key,
            }}
            onClose={onError}
            onSuccess={onSuccess}
          />
        )}

        {paymentMethod === PAYMENT_METHODS.MONO_DIRECT_PAY && paymentData !== undefined && (
          <MonoDirectDebit
            paymentData={{
              amount: paymentData?.total_amount,
              payment_id: paymentData.mono_payment_id,
              publicKey: paymentData.public_key,
            }}
            onClose={onError}
            callback={onSuccess}
          />
        )}
      </Portal>
      <Head>
        <script src="https://js.paystack.co/v1/inline.js" async></script>
      </Head>
    </>
  );
};

export default MakePayment;
